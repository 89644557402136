import React, { useState, useEffect } from "react";
import { everbuildAddress, everbuildAbi } from "../constants/constants";
import CountdownTimer from "./CountdownTimer";
import {
  MAX_SUPPLY,
  MAX_PUBLIC_SUPPLY,
  MAX_WHITELIST_SUPPLY,
} from "../constants/constants";
import info from "../assets/info_icon.png";
import { Toaster } from "react-hot-toast";
import { eth, getContractInstance } from "../utils/contractUtils";

function Home({ user }) {
  const [mintedTokenCount, setMintedTokenCount] = useState(0);
  const [whitelistMintedSupply, setWhitelistMintedSupply] = useState(0);
  const [totalEverbuildSupply, setTotalEverbuildSupply] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [royaltiesWithdrawalTimestamp, setRoyaltiesWithdrawalTimestamp] =
    useState(0);

  const everbuildContract = getContractInstance(everbuildAddress, everbuildAbi);

  async function fetchMintedTokenCount() {
    const mintedTokens = await everbuildContract.MAX_PUBLIC_SUPPLY();
    const convertToNum = mintedTokens.toNumber();
    setMintedTokenCount(convertToNum);
  }

  async function fetchWhitelistTokenCount() {
    const mintedTokens = await everbuildContract.MAX_WHITELIST_SUPPLY();
    const convertToNum = mintedTokens.toNumber();
    setWhitelistMintedSupply(convertToNum);
  }

  async function fetchTotalEverbuildSupply() {
    const totalEverbuildSupply = await everbuildContract.totalSupply();
    const convertToNum = totalEverbuildSupply.toNumber();
    setTotalEverbuildSupply(convertToNum);
  }

  async function fetchTotalClaimed() {
    const totalClaimed = await everbuildContract.royaltiesTotalClaimed();
    const convertToNum = totalClaimed.toNumber() / 1000000;
    setTotalClaimed(convertToNum);
  }

  async function fetchRoyaltiesWithdrawalTimestamp() {
    const timestamp = await everbuildContract.royaltiesWithdrawalTimestamp();
    setRoyaltiesWithdrawalTimestamp(timestamp.toNumber()); // No need to multiply by 1000
  }

  useEffect(() => {
    if (!eth() || !user) {
      return;
    } else {
      fetchMintedTokenCount();
      fetchWhitelistTokenCount();
      fetchTotalEverbuildSupply();
      fetchTotalClaimed();
      fetchRoyaltiesWithdrawalTimestamp();
    }
  }, [user]);

  return (
    <div className=" bg-black text-white">
      <Toaster />
      <h1 className="text-white bg-transparent font-extrabold text-3xl lg:text-4xl xl:text-6xl text-center pt-8 lg:pt-12">
        Welcome to
        <span className="bg-clip-text text-transparent text-4xl lg:text-5xl xl:text-7xl 2xl:text-8xl bg-gradient-to-r from-orange-300 via-orange-600 to-red-600 ">
          <br></br>The EVERBUILD
        </span>
        <br></br>
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-emerald-500 to-amber-500 text-3xl lg:text-4xl xl:text-6xl">
          DASHBOARD
        </span>
      </h1>

      <div>
        <h2 className="h-fit w-fit font-bold text-2xl lg:text-3xl pb-5 mt-20 mx-10 lg:mx-56 bg-clip-text text-transparent bg-gradient-to-l from-violet-400 from-10% via-sky-500 via-70% to-emerald-500 to-90%">
          Platform Statistics
        </h2>

        <div className="flex flex-col lg:flex-row justify-between items-center bg-gray-800 rounded-xl mx-10 lg:mx-56 px-5 lg:px-16 py-7 text-lg font-light ">
          <div>
                      <div className="flex-col flex items-center">{/*}
              <div className="flex flex-row items-center justify-center gap-1">
                <p>ECT Total Minted</p>

                <abbr title="ECT Total Minted">
                  <img
                    src={info}
                    alt="info"
                    className="bg-white rounded-full h-5 w-5"
                  />
                </abbr>
              </div>
              <p className="font-extrabold text-xl ">
                {totalEverbuildSupply} / {MAX_SUPPLY}
              </p>*/}
            </div>
            <br />
            {/* <div className="flex-col flex items-center">
              <div className="flex flex-row items-center justify-center gap-1">
                <p className="">ECT Held By Team</p>
                <abbr title="ECT Held By Team">
                  <img
                    src={info}
                    alt="info"
                    className="bg-white rounded-full h-5 w-5"
                  />
                </abbr>
              </div>
              <p className="font-extrabold text-xl">
                {whitelistMintedSupply + mintedTokenCount}
              </p>
            </div> */}
          </div>

          <div>
            <div className="flex-col flex items-center">
              <div className="flex flex-row items-center justify-center gap-1">
                <p>Airdrop ERTS claimed</p>

                <abbr title="Whitelist ERTS claimed">
                  <img
                    src={info}
                    alt="info"
                    className="bg-white rounded-full h-5 w-5"
                  />
                </abbr>
              </div>
              <p className="font-extrabold text-xl">
                {whitelistMintedSupply} / {MAX_WHITELIST_SUPPLY}
              </p>
            </div>
            <br />
            {/* <div className="flex-col flex items-center">
              <div className="flex flex-row items-center justify-center gap-1">
                <p className="">Public ECT minted</p>

                <abbr title="Public ECT minted">
                  <img
                    src={info}
                    alt="info"
                    className="bg-white rounded-full h-5 w-5"
                  />
                </abbr>
              </div>
              <p className="font-extrabold text-xl ">
                {mintedTokenCount} / {MAX_PUBLIC_SUPPLY}
              </p>
            </div> */}
          </div>

                  <div>{/*
            <div className="flex-col flex items-center">
              <div className="flex flex-row items-center justify-center gap-1">
                <p className="">Current Royalty Payouts</p>

                <abbr title="Current Royalty Payouts">
                  <img
                    src={info}
                    alt="info"
                    className="bg-white rounded-full h-5 w-5"
                  />
                </abbr>
              </div>
              <p className="font-extrabold text-xl ">{totalClaimed}</p>
            </div>
            <br />
            <div className="flex-col flex items-center">
              <div className="flex flex-row items-center justify-center gap-1">
                <h4>Time remaining to claim royalties</h4>

                <abbr title="Time remaining to claim royalties">
                  <img
                    src={info}
                    alt="info"
                    className="bg-white rounded-full h-5 w-5"
                  />
                </abbr>
              </div>
              <div>
                {royaltiesWithdrawalTimestamp === 0 ? (
                  <p className="font-bold text-xl text-red-500">
                    No Royalties Payments Active
                  </p>
                ) : (
                  <CountdownTimer endTime={royaltiesWithdrawalTimestamp} />
                )}
              </div>
            </div>*/}
          </div>
        </div>
      </div>

      <div className="pt-16 pb-20 mx-10 lg:mx-56 ">
        <h2>
                  Welcome to the Everbuild Reward Token Claimable Free Airdrop Dashboard. Click the Airdrop link on the top navigation menu to get started and claim your ERTs!
        </h2>
        <br />
        <h2>
                  Everbuild is a freelance web 3.0 marketplace connecting talent and
                  professionals with crypto business start-ups in the blockchain space.
        </h2>
      </div>
    </div>
  );
}

export default Home;
