/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { everbuildAddress, everbuildAbi } from "../constants/constants";
import toast, { Toaster } from "react-hot-toast";
import RoyaltiesPresentation from "../JSX/RoyaltiesPresentation";
import { eth, getContractInstance } from "../utils/contractUtils";

export default function Royalties({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [royaltiesOpen, setRoyaltiesOpen] = useState(false);
  const [totalVaultSupply, setTotalVaultSupply] = useState(0);
  const [totalPaidOut, setTotalPaidOut] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [royaltiesWithdrawalTimestamp, setRoyaltiesWithdrawalTimestamp] =
    useState(0);
  const everbuildContract = getContractInstance(everbuildAddress, everbuildAbi);


  async function isRoyaltiesOpen() {
    const isOpen = await everbuildContract.royaltiesClaimedEnabled();
    setRoyaltiesOpen(isOpen);
  }
  async function totalInVault() {
    const total = await everbuildContract.tokensToClaim();
    const totalNumber = total.toNumber(); // Convert balance to a number
    setTotalVaultSupply(totalNumber);
  }
  async function userBalanceInVault() {
    const balance = await everbuildContract.claimable(user);
    const balanceNumber = balance.toNumber() / 1000000;
    setUserBalance(balanceNumber);
  }

  async function claimRoyalties() {
    if (user && userBalance > 0) {
      setIsLoading(true);
      try {
        const tx = await everbuildContract.claimRoyalties();
        await tx.wait();
        alert("Successfully claimed royalties"); // use Toast here
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  }

  async function getTotalPaidOut() {
    const total = await everbuildContract.totalRoyaltiesClaimed(user);
    const totalNumber = total.toNumber() / 1000000;
    setTotalPaidOut(totalNumber);
  }

  async function fetchRoyaltiesWithdrawalTimestamp() {
    const timestamp = await everbuildContract.royaltiesWithdrawalTimestamp();
    setRoyaltiesWithdrawalTimestamp(timestamp.toNumber());
  }

  useEffect(() => {
    if (!eth()) {
      return;
    } else {
      isRoyaltiesOpen();
      getTotalPaidOut();
      totalInVault();
      userBalanceInVault();
      fetchRoyaltiesWithdrawalTimestamp();
    }
  }, [user]);

  useEffect(() => {
    if (!eth()) {
      return;
    } else {
      const contract = getContractInstance(everbuildAddress, everbuildAbi);
      contract.on("RoyaltiesClaimed", (user, amount) => {
        totalInVault();
        userBalanceInVault();
      });
      contract.on("RoyaltiesClaimedEnabledChanged", (enabled) => {
        setRoyaltiesOpen(enabled);
        if (enabled === true) {
          totalInVault();
          userBalanceInVault();
          fetchRoyaltiesWithdrawalTimestamp();
        }
      });

      return () => {
        contract.removeAllListeners();
      };
    }
  }, [user]);

  return (
    <RoyaltiesPresentation
      royaltiesOpen={royaltiesOpen}
      user={user}
      totalPaidOut={totalPaidOut}
      userBalance={userBalance}
      totalVaultSupply={totalVaultSupply}
      royaltiesWithdrawalTimestamp={royaltiesWithdrawalTimestamp}
      claimRoyalties={claimRoyalties}
      isLoading={isLoading}
    />
  );
}
