import React from "react";
import CountdownTimer from "../components/CountdownTimer";

export default function RoyaltiesPresentation({
  royaltiesOpen,
  user,
  totalPaidOut,
  userBalance,
  totalVaultSupply,
  royaltiesWithdrawalTimestamp,
  claimRoyalties,
  isLoading,
}) {
 
  return (
    <div className="h-screen bg-black text-white flex flex-col items-center">
      <h1 className="text-white bg-transparent font-extrabold text-4xl lg:text-6xl xl:text-6xl text-center pt-10 lg:pt-16 mb-16">
        welcome to
        <span className="bg-clip-text text-transparent text-5xl lg:text-7xl 2xl:text-8xl bg-gradient-to-r from-orange-300 to-yellow-300 mb-16">
          <br></br>ROYALTIES
        </span>
      </h1>
      {!royaltiesOpen && <h3 className="flex justify-center text-3xl font-bold">COMING SOON</h3>}

      {!user && (
        <>
          <h2 className="my-4 mt-8 text-xl lg:text-2xl font-light">
            connect your wallet
          </h2>
        </>
      )}

      {royaltiesOpen && userBalance === 0 && totalPaidOut === 0 && (
        <h3 className="text-white">You are not eligible to claim royalties</h3>
      )}

      {user && royaltiesOpen && (userBalance > 0 || totalPaidOut > 0) && (
        <div className="flex justify-center flex-col">
          <div className="mb-10 text-lg lg:text-xl xl:text-2xl font-semibold">
            <div className="flex flex-row justify-center">
              <h4>Balance : &nbsp;</h4>
              <p>{userBalance}</p>
            </div>
            <div className="flex flex-row justify-center">
              <h4>Total Paid Out : &nbsp;</h4>
              <p>{totalPaidOut}</p>
            </div>

            <div className="flex flex-row justify-center">
              <h4>This months payout claimed: &nbsp;</h4>
              {userBalance === 0 ? <p className="text-red-500">Claimed</p> : 0}
            </div>
            <br></br>
            <br></br>
            <h2 className="flex flex-row justify-center">{`Total money in 🏦 payout vault : ${totalVaultSupply}`}</h2>

            <div className="flex justify-center mt-16">
              <h4>Time remaining to claim royalties : &nbsp;</h4>
              <CountdownTimer endTime={royaltiesWithdrawalTimestamp} />
            </div>
          </div>

          <div className="flex justify-center">
            {!isLoading && userBalance > 0 ? (
              <button
                onClick={claimRoyalties}
                className="px-6 py-2.5 bg-gradient-to-r from-orange-300 to-yellow-300 mb-16 rounded-full text-black font-semibold mt-10 text-lg lg:text-lg xl:text-xl"
              >
                Claim Royalties
              </button>
            ) : (
              isLoading && (
                <p className="text-white mt-10 text-lg lg:text-xl xl:text-2xl">
                  Claiming royalties...
                </p>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}
