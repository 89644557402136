import {ethers} from "ethers";
import toast from "react-hot-toast";

export function eth() {
  const { ethereum } = window;

  return ethereum || null;
}

export function getContractInstance(address, contractAbi) {
    const ethereum = eth();
    if (!ethereum) {
        toast.error("Ethereum provider not found. Install or enable MetaMask.");
        return;
    }
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    
    return new ethers.Contract(address, contractAbi, signer);
}


