/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  everbuildAddress,
  everbuildAbi,
  MAX_WHITELIST_SUPPLY,
} from "../constants/constants.js";
import toast from "react-hot-toast";
import "../index.css";
import {eth, getContractInstance} from "../utils/contractUtils.js";

export default function Whitelist({ user }) {
  const [mintedTokenCount, setMintedTokenCount] = useState(0);
  const [whitelistOpen, setWhitelistOpen] = useState(false);
  const [whitelistMintLimitReached, setWhitelistMintLimitReached] =
    useState(true);
  const [allowedWhitelistMint, setAllowedWhitelistMint] = useState(0);

  const everbuildContract = getContractInstance(everbuildAddress, everbuildAbi);
  

  async function fetchMintedTokenCount() {
    const mintedTokens = await everbuildContract.MAX_WHITELIST_SUPPLY();
    const convertToNum = mintedTokens.toNumber();
    setMintedTokenCount(convertToNum);
  }

  async function fetchInitialMintStates() {
    const whitelist = await everbuildContract.whitelistMintEnabled();
    setWhitelistOpen(whitelist);
  }

  async function fetchWhitelistAmount() {
    const allowedWhitelistAmount = await everbuildContract.whitelistAmount(
      user
    );
    const convertToNum = allowedWhitelistAmount.toNumber();
    setAllowedWhitelistMint(convertToNum);

    if (convertToNum === 0) {
      setWhitelistMintLimitReached(true);
    } else {
      setWhitelistMintLimitReached(false);
    }
  }

  async function handleWhitelistMint() {
    if (allowedWhitelistMint > 0) {
      try {
        const tx = await everbuildContract.mintWithEverburn(
          allowedWhitelistMint
        );
        await tx.wait();

        fetchWhitelistAmount();
      } catch (error) {
        toast.error("Minting failed", error);
      }
    } else {
      toast.error("Minting not allowed. Your whitelist mint limit is reached.");
    }
  }

  useEffect(() => {
    if (!eth() || !user) {
      return;
    } else {
      fetchInitialMintStates();
      fetchWhitelistAmount();
      fetchMintedTokenCount();
    }
  }, [user]);

  useEffect(() => {
    if (!eth() || !user) {
      return;
    } else {
      const contract = getContractInstance(everbuildAddress, everbuildAbi);
      contract.on("WhitelistMintEnabledChanged", (enabled) => {
        setWhitelistOpen(enabled);
      });
      contract.on("Minted", (user, tokenId) => {
        fetchMintedTokenCount();
      });

      return () => {
        contract.removeAllListeners();
      };
    }
  }, [user]);

  return (
    <div className="bg-black lg:py-18 pb-6 h-screen">
      <h1 className="text-white bg-transparent font-extrabold text-4xl lg:text-6xl xl:text-6xl text-center pt-8 lg:pt-12">
        welcome to
        <span className="bg-clip-text text-transparent text-5xl lg:text-7xl 2xl:text-8xl bg-gradient-to-r from-orange-300 via-orange-600 to-red-600 ">
          <br></br>The EVERBUILD
        </span>
        <br></br>
        <span className="text-green-500 text-4xl lg:text-6xl xl:text-7xl">
          <span className="text-white">Airdrop</span> Claim
        </span>
      </h1>

      {mintedTokenCount >= MAX_WHITELIST_SUPPLY ? (
        <h1 className="text-red-500">SOLD OUT</h1>
      ) : (
        <>
          {!whitelistOpen ? (
            <h4 className="flex justify-center items-center mt-24 mb-10 text-red-500 font-medium text-3xl lg:text-3xl">
              Airdrop claiming is not open yet. Please check back later.
            </h4>
          ) : !whitelistMintLimitReached ? (
            <>
              <div className="pt-16 mb-5 mx-10 lg:mx-56 text-white">
                <h2>
                  Congratulations! Your address has been whitelisted and is
                  eligible to receive free ERTs for being a loyal Everburn
                  holder. You are eligible to receive {allowedWhitelistMint}{" "}
                  ERTs. Please click the Mint button to receive your ECTs.
                </h2>
              </div>
              <div className="text-white mt-12">
                <h4 className="flex justify-center items-center flex-row text-green-500 font-bold text-lg lg:text-xl xl:text-2xl mb-4">
                  Whitelist Mint is open
                </h4>

                <div className="flex justify-center items-center flex-col md:flex-row md:gap-3 mt-6 md:mt-8">
                  <p className="text-white font-bold lg:text-xl xl:text-2xl ">
                    Minted tokens: {mintedTokenCount}/{MAX_WHITELIST_SUPPLY}
                  </p>
                  <button
                    onClick={handleWhitelistMint}
                    className="py-3 md:px-6 px-[172px] font-bold hover:opacity-80 rounded-full text-black disabled:cursor-not-allowed bg-white"
                  >
                    Whitelist Mint
                  </button>
                </div>
              </div>
            </>
          ) : (
            <h4 className="flex justify-center items-center mt-24 text-red-500 font-medium text-2xl lg:text-3xl">
              You have minted all your allowed NFTs or are not part of the whitelist.
            </h4>
          )}
        </>
      )}

      {!user && (
        <p className="text-white flex justify-center text-lg font-semibold lg:text-xl xl:text-2xl 2xl:text-2xl ">
          Please connect your metamask
        </p>
      )}
    </div>
  );
}
