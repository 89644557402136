/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  everburnAddress,
  everbuildAddress,
  everburnAbi,
  everbuildAbi,
  MAX_PUBLIC_SUPPLY,
  MAX_MINT,
} from "../constants/constants.js";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import "../index.css";
import MintPresentation from "../JSX/MintPresentation";
import {eth, getContractInstance} from "../utils/contractUtils.js";

function Mint({ user }) {
  const [publicOpen, setPublicOpen] = useState(false);
  const [useAvaxAsPayment, setUseAvaxAsPayment] = useState(false);
  const [allowance, setAllowance] = useState(0);

  const [numToMint, setNumToMint] = useState(1);
  const [userEverburnBalance, setUserEverburnBalance] = useState(0);
  const [mintedTokenCount, setMintedTokenCount] = useState(0);
  const [pricePerMint, setPricePerMint] = useState(0);

  const [mintableTokensLeft, setMintableTokensLeft] = useState(0);
  const [maxMintReached, setMaxMintReached] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);

  const [useAvax, setUseAvax] = useState(false);
  const [nftPriceUsingAVAX, setNftPriceUsingAVAX] = useState(0);

  const everburnContract = getContractInstance(everburnAddress, everburnAbi);
  const everbuildContract = getContractInstance(everbuildAddress, everbuildAbi);

  
  async function fetchAvaxPaymentStatus() {
    const paymentStatus = await everbuildContract.acceptAVAX();
    setUseAvaxAsPayment(paymentStatus);
  }

  async function fetchEverburnBalance() {
    const balance = await everburnContract.balanceOf(user);
    console.log("balance printed", balance.toNumber());
    setUserEverburnBalance(balance.toNumber());
  }

  async function fetchMintedTokenCount() {
    const mintedTokens = await everbuildContract.MAX_PUBLIC_SUPPLY();
    const convertToNum = mintedTokens.toNumber();
    setMintedTokenCount(convertToNum);
  }

  async function fetchAllowance() {
    const _allowance = await everburnContract.allowance(user, everbuildAddress);
    console.log("allowance printed", _allowance.toNumber());
    setAllowance(_allowance.toNumber());
  }

  async function fetchPricePerMint() {
    const price = await everbuildContract.price();
    const pricePerMint = price.toNumber();
    setPricePerMint(pricePerMint);
  }

  async function fetchNftPriceUsingAVAX() {
    const price = await everbuildContract.nftPriceUsingAVAX();
    setNftPriceUsingAVAX(price.toNumber());
  }

  async function checkMintLimit() {
    const mintedTokens = parseInt(
      (await everbuildContract._mintedTokens(user)).toString()
    );
    const totalLeft = MAX_MINT - mintedTokens;
    setMintableTokensLeft(totalLeft);

    if (mintedTokens >= MAX_MINT) {
      setMaxMintReached(true);
    } else {
      setMaxMintReached(false);
    }
  }

  async function fetchInitialMintStates() {
    const publicMintEnabled = await everbuildContract.publicMintEnabled();
    setPublicOpen(publicMintEnabled);
  }

  async function handleApprove() {
    try {
      setApprovalLoading(true);
      const everburnPerNFT = pricePerMint;
      const requiredAmount = numToMint * everburnPerNFT;

      if (userEverburnBalance < requiredAmount) {
        //alert("You don't have enough Everburn tokens to approve this amount.");
        toast.error(
          "You don't have enough Everburn tokens to approve this amount.",
          {
            duration: 5000,
            position: "top-center",
          }
        );
        return;
      }

      const gasOptions = {
        gasLimit: 60000, // Example value, set the desired gas limit here
        // gasPrice: ethers.utils.parseUnits('20', 'gwei'), // Uncomment this line and set the desired gas price if you want to set it manually
      };

      const tx = await everburnContract.approve(
        everbuildAddress,
        requiredAmount,
        gasOptions
      );

      await tx.wait();
      toast.success("Approval Successful", {
        duration: 5000,
        position: "top-center",
      });
      setApprovalStatus(true);
      fetchAllowance();
    } catch (error) {
      toast.error("Approval failed", error);
    } finally {
      setApprovalLoading(false);
    }
  }

  async function handlePublicMint() {
    const mintedTokens = parseInt(
      (await everbuildContract._mintedTokens(user)).toString()
    );

    if (
      numToMint > 0 &&
      numToMint <= MAX_MINT &&
      mintedTokens + numToMint <= MAX_MINT
    ) {
      // Check allowance if using Everburn tokens
      if (!useAvax) {
        const requiredAllowance = pricePerMint * numToMint;

        if (allowance < requiredAllowance) {
          alert(
            "Insufficient Allowance. Please approve the required amount of tokens for minting."
          );
          return;
        }
      }

      try {
        setMintLoading(true);

        const tx = useAvax
          ? await everbuildContract.mintMultiple(numToMint, true, {
              value: ethers.utils.parseUnits(
                ((nftPriceUsingAVAX * numToMint) / 10 ** 18).toString(),
                "ether"
              ),
            })
          : await everbuildContract.mintMultiple(numToMint, false);
        await tx.wait();
        toast.success("Minted");
        await checkMintLimit();
        setApprovalStatus(false);
      } catch (error) {
        toast.error("Minting failed", error);
      } finally {
        setMintLoading(false);
      }
    } else {
      toast.error(
        `Minting not allowed. You are exceeding the mint limit. You are only allowed to mint ${mintableTokensLeft} NFTs per wallet.`
      );
    }
  }

  useEffect(() => {
    if (!eth()) {
      return;
    } else {
      fetchInitialMintStates();
      checkMintLimit();
      fetchEverburnBalance();
      fetchMintedTokenCount();
      fetchPricePerMint();
      fetchAllowance();
      fetchNftPriceUsingAVAX();
      fetchAvaxPaymentStatus();
    }
  }, [user]);

  useEffect(() => {
    if (!eth()) {
      return;
    } else {
      const contract = getContractInstance(everbuildAddress, everbuildAbi);
      contract.on("PublicMintEnabledChanged", (enabled) => {
        setPublicOpen(enabled);
      });
      contract.on("Minted", (user, tokenId) => {
        fetchMintedTokenCount();
        checkMintLimit();
      });

      return () => {
        contract.removeAllListeners();
      };
    }
  }, [user]);

  function increment() {
    if (numToMint < mintableTokensLeft && numToMint < MAX_MINT) {
      setNumToMint(numToMint + 1);
    } else {
      toast.error(
        `You are only allowed to mint ${mintableTokensLeft} more NFT(s) per wallet.`
      );
    }
  }

  function decrement() {
    if (numToMint > 1) {
      setNumToMint(numToMint - 1);
    } else {
      toast.error("Minimum mint amount is 1 NFT.");
    }
  }

  return (
    <MintPresentation
      mintedTokenCount={mintedTokenCount}
      MAX_PUBLIC_SUPPLY={MAX_PUBLIC_SUPPLY}
      MAX_MINT={MAX_MINT}
      publicOpen={publicOpen}
      user={user}
      maxMintReached={maxMintReached}
      approvalLoading={approvalLoading}
      approvalStatus={approvalStatus}
      numToMint={numToMint}
      setNumToMint={setNumToMint}
      allowance={allowance}
      mintLoading={mintLoading}
      pricePerMint={pricePerMint}
      nftPriceUsingAVAX={nftPriceUsingAVAX}
      useAvax={useAvax}
      useAvaxAsPayment={useAvaxAsPayment}
      setUseAvax={setUseAvax}
      decrement={decrement}
      increment={increment}
      handleApprove={handleApprove}
      handlePublicMint={handlePublicMint}
      userEverburnBalance={userEverburnBalance}
      mintableTokensLeft={mintableTokensLeft}
    />
  );
}

export default Mint;
