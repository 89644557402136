import React, { useState, useEffect } from "react";

function useCountdown(endTime) {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const remaining = endTime - now;

      if (remaining <= 0) {
        clearInterval(intervalId);
        setTimeRemaining(0);
      } else {
        setTimeRemaining(remaining);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  return timeRemaining;
}

function formatTimeRemaining(seconds) {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
}

const CountdownTimer = ({ endTime }) => {
  const timeRemaining = useCountdown(endTime);
  return (
    <div className="flex flex-row">
      <p className="text-green-500">
        {timeRemaining !== null
          ? formatTimeRemaining(timeRemaining)
          : "Loading..."}
      </p>
    </div>
  );
};

export default CountdownTimer;
