/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Mint from "./components/Mint";
import Royalties from "./components/Royalties";
import Whitelist from "./components/Whitelist";
import Footer from "./components/Footer";
import toast, {Toaster} from "react-hot-toast";
import {eth} from "./utils/contractUtils.js";

function App() {

  const [user, setUser] = useState(null);
  
  async function connectWallet() {
    const ethereum = eth();
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });

    if (accounts.length === 0) {
      toast.error("You need to connect to metamask");
      return;
    }

    setUser(accounts[0]);
  }

  async function checkIfWalletIsConnected() {
    const ethereum = eth();

    const accounts = await ethereum.request({ method: "eth_accounts" });
    if (accounts.length !== 0) {
      setUser(accounts[0]);
    } else {
      toast.error("Connect your wallet to continue");
    }
  }

  useEffect(() => {
    if (!eth()) {
      toast.error('Please use the MetaMask browser or a web3-enabled browser on mobile to interact with this DApp.');
      return;
    } else {
      checkIfWalletIsConnected();
    }
  }, [user]);

  return (
    <Router>
      <Toaster />
      <Navbar user={user} connectWallet={connectWallet} />
      <Routes>
        <Route path="/" exact element={<Home user={user} />} />
        <Route path="/mint" element={<Mint user={user} />} />
        <Route path="/whitelist" element={<Whitelist user={user} />} />
        <Route path="/royalties" element={<Royalties user={user} />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
