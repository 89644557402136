import React from "react";
import toast, { Toaster } from "react-hot-toast";
export default function MintPresentation({
  mintedTokenCount,
  MAX_PUBLIC_SUPPLY,
  MAX_MINT,
  publicOpen,
  user,
  maxMintReached,
  approvalLoading,
  approvalStatus,
  numToMint,
  setNumToMint,
  allowance,
  mintLoading,
  pricePerMint,
  nftPriceUsingAVAX,
  useAvax,
  useAvaxAsPayment,
  setUseAvax,
  decrement,
  increment,
  handleApprove,
  handlePublicMint,
  userEverburnBalance,
  mintableTokensLeft,
}) {
  console.log("useAvaxAsPayment", useAvaxAsPayment);
  return (
    <div className="bg-black lg:py-18 h-screen">
      <Toaster />

      <h1 className="text-white bg-transparent font-extrabold text-4xl lg:text-6xl xl:text-6xl text-center pt-10 lg:pt-16">
        welcome to
        <span className="bg-clip-text text-transparent text-5xl lg:text-7xl 2xl:text-8xl bg-gradient-to-r from-orange-300 via-orange-600 to-red-600 ">
          <br></br>The EVERBUILD
        </span>{" "}
        <span className="text-green-500 text-4xl lg:text-6xl xl:text-7xl">
          Mint
        </span>
      </h1>
      <div className="mt-10">
        {!publicOpen ? (
          <h3 className="flex justify-center items-center mt-24 mb-10 text-red-500 font-medium text-2xl lg:text-5xl">
            Mint Coming soon
          </h3>
        ) : (
          <h4 className="flex justify-center items-center flex-row text-green-500 font-bold text-2xl">
            Public Mint is open
          </h4>
        )}
        <p className="text-white font-bold text-2xl flex justify-center items-center ">
          Minted tokens: {mintedTokenCount}/{MAX_PUBLIC_SUPPLY}
          {mintedTokenCount >= MAX_PUBLIC_SUPPLY && <span> - Sold Out!</span>}
        </p>
      </div>

      {user && mintedTokenCount < MAX_PUBLIC_SUPPLY && (
        <div className="flex justify-center items-center w-full mt-6">
          <div className="flex justify-center items-center flex-col w-fit px-20 py-5 rounded-3xl md:bg-gradient-to-r from-indigo-600 from-10% via-sky-600 via-30% to-emerald-600 to-90%">
            {publicOpen && (
              <div className="text-white ">
                {maxMintReached ? (
                  <p className="text-red-400">
                    You have reached the max mint limit
                  </p>
                ) : (
                  <div className="">
                    <h2 className="flex justify-center items-center text-xl font-bold">
                      Max mintable NFTs {mintableTokensLeft}
                    </h2>

                    <label className="flex justify-center items-center flex-col text-xl font-bold">
                      {!useAvax ? (
                        <>
                          <h2>
                            Your balance:{" "}
                            {(userEverburnBalance / 10 ** 6).toFixed(2)} EVB
                          </h2>
                          <h2>Price per nft is {pricePerMint / 10 ** 6} EVB</h2>
                        </>
                      ) : (
                        <>
                          <h2>
                            Price per nft is {nftPriceUsingAVAX / 10 ** 18} AVAX
                          </h2>
                        </>
                      )}
                      {((!approvalLoading && !approvalStatus) ||
                        !mintLoading) && (
                        <div className="my-4">
                          {useAvaxAsPayment && (
                            <label className="text-white font-semibold inline-flex items-center ">
                              <input
                                type="radio"
                                value={true}
                                checked={useAvax}
                                onChange={(e) =>
                                  setUseAvax(e.target.value === "true")
                                }
                                className="form-radio text-orange-600"
                              />
                              <span className="ml-2">Pay with AVAX</span>
                            </label>
                          )}
                          <label
                            className="text-white font-semibold inline-flex items-center ml-6"
                            style={{
                              display: !useAvaxAsPayment
                                ? "none"
                                : "inline-flex",
                            }}
                          >
                            <input
                              type="radio"
                              value={false}
                              checked={!useAvax}
                              onChange={(e) =>
                                setUseAvax(e.target.value === "true")
                              }
                              className="form-radio text-orange-600"
                            />
                            <span className="ml-2">
                              Pay with Everburn tokens
                            </span>
                          </label>
                        </div>
                      )}

                      {((!approvalLoading && !approvalStatus) ||
                        !mintLoading) && (
                        <div className="container">
                          <button
                            className="text-2xl px-1 ml-2 my-1"
                            onClick={decrement}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={numToMint}
                            onChange={(e) => {
                              const inputValue = parseInt(e.target.value);
                              if (
                                inputValue >= 1 &&
                                inputValue <=
                                  Math.min(mintableTokensLeft, MAX_MINT)
                              ) {
                                setNumToMint(inputValue);
                              } else {
                                toast.error(
                                  `You are only allowed to mint ${mintableTokensLeft} more NFT(s) per wallet.`
                                );
                              }
                            }}
                            min="1"
                            max={Math.min(mintableTokensLeft, MAX_MINT)}
                            className="padding-x-2 text-xl"
                            id="numToMint"
                          />
                          <button
                            className="text-2xl flex justify-center items-center px-1 mr-2"
                            onClick={increment}
                          >
                            +
                          </button>
                        </div>
                      )}
                    </label>

                    <div className="flex justify-center items-center flex-col gap-3 md:flex-row mt-16 md:mt-5">
                      {!useAvax && (
                        <div className="Approve-Button-Container">
                          {approvalLoading ? (
                            <p className="text-yellow-500">
                              Approving, please wait...
                            </p>
                          ) : (
                            <button
                              onClick={handleApprove}
                              disabled={approvalStatus}
                              className="py-3 md:py-2 md:px-6 px-8 font-bold hover:opacity-80 rounded-full text-white bg-orange-600"
                            >
                              Approve
                            </button>
                          )}
                        </div>
                      )}

                      <div className="Mint-Button-Container">
                        {mintLoading ? (
                          <p className="text-yellow-500">
                            Minting, please wait...
                          </p>
                        ) : (
                          <button
                            onClick={handlePublicMint}
                            disabled={
                              !useAvax &&
                              (!approvalStatus ||
                                allowance < numToMint * pricePerMint)
                            }
                            className="py-3 md:py-2 md:px-6 px-8 font-bold hover:opacity-80 rounded-full text-black disabled:cursor-not-allowed bg-white"
                          >
                            Mint
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
