import { Dialog, Popover } from "@headlessui/react";
import { Bars2Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Logo from "../assets/logo-1.png";
import { Link } from "react-router-dom";

const navItems = [
  { name: 'HOME', path: '/' },
  // { name: 'ROYALTIES', path: '/royalties' },
  // { name: 'MINT', path: '/mint' },
  { name: 'AIRDROP', path: '/whitelist' },
];

export default function Navbar({ connectWallet, user }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-black ">
      <nav className="flex items-center justify-between">
        <div className="flex lg:flex-1">
          <Link to="/" className="m-1.5 p-1.5">
            <img
              className="h-16 w-auto"
              width={1000}
              height={300}
              src={Logo}
              alt=""
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          {navItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="text-sm font-semibold leading-6 text-gray-300 hover:text-white"
            >
              {item.name}
            </Link>
          ))}
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center lg:mr-8 space-x-6">
          <button
            onClick={connectWallet}
            className="mt-4 bg-gray-700 text-white px-3 py-2 rounded-full cursor-pointer hover:bg-gray-600 block text-center font-bold"
          >
            {user ? `${user.substring(0, 5)}...${user.substring(user.length - 5)}` : "Connect Wallet"}
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="">
              <img
                className="h-16 w-auto"
                width={1000}
                height={300}
                src={Logo}
                alt=""
              />
            </Link>
            <button
              type="button"
              className="rounded-md text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.path}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-100 hover:bg-blue-600"
                  >
                    {item.name}
                  </Link>
                ))}
                <button
                  onClick={connectWallet}
                  className="mt-4 bg-gray-700 text-white px-3 py-2 rounded-full cursor-pointer hover:bg-gray-600 block w-full text-center font-bold"
                >
                  {user ? `${user.substring(0, 5)}...${user.substring(user.length - 5)}` : "Connect Wallet"}
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

