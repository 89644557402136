import React from "react";

const Footer = () => {
  return (
    <div className="">
      <div className="text-white bg-black flex justify-center py-5 text-lg">
       
        <a href="https://everburn.io/">Everburn</a>&nbsp;|&nbsp;
        <a href="https://linktr.ee/everburn">Linktree</a>
      </div>
    </div>
  );
};

export default Footer;
